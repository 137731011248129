var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "router-link",
        { staticClass: "btn", attrs: { to: { name: "Login" } } },
        [
          _c("i", { staticClass: "nova-arrow-left" }),
          _vm._v(" " + _vm._s(_vm.$t("back")))
        ]
      ),
      _vm._v(" "),
      _c("h1", [_vm._v(_vm._s(_vm.$t("label_new_password")))]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "", method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("password-input", {
                attrs: { name: "pwd", disabled: _vm.status, required: true },
                on: {
                  "pwd-changed": function(value) {
                    return (_vm.p1 = value)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("password-input", {
                attrs: {
                  name: "pwdConfirm",
                  disabled: _vm.status,
                  required: true
                },
                on: {
                  "pwd-changed": function(value) {
                    return (_vm.p2 = value)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pwdNotMatching,
                  expression: "pwdNotMatching"
                }
              ],
              staticClass: "pwd-not-matching"
            },
            [_vm._v(_vm._s(_vm.$t("error_passwords_not_matching")))]
          ),
          _vm._v(" "),
          _c(
            "save-button",
            { attrs: { enabled: _vm.saveEnabled, status: _vm.status } },
            [
              _c("template", { slot: "save" }, [
                _vm._v(_vm._s(_vm.$t("reset_password")))
              ]),
              _vm._v(" "),
              _c("template", { slot: "saving" }, [
                _vm._v(_vm._s(_vm.$t("resetting")))
              ]),
              _vm._v(" "),
              _c("template", { slot: "success" }, [
                _vm._v(_vm._s(_vm.$t("success")))
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }