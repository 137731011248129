<template>
    <div>
        <div class="avatar-container">
            <vue-croppie 
                v-if="inputFile"
                ref="croppieRef"
                :enableOrientation="true"
                :enableResize="false"
                :mouseWheelZoom="false"
                :viewport="{ width: 200, height: 200, type: 'circle' }"
                :boundary="{ width: 200, height: 200 }"
            />
            <img v-else :src="avatarUrl" id="current-avatar" ref="currentAvatar" />
        </div>
        <div  class="upload-btns-wrapper">
            <label class="file-input btn secondary">
                {{ $t('upload_image') }}
                <input 
                    id="croppie-file-input"
                    name="croppie-file-input"
                    ref="croppieFileInput"
                    type="file" 
                    accept="image/png, image/jpeg, image/jpg, image/svg"
                    @change="bindFile"
                />
            </label>
            <button type="button" class="btn danger" @click="deleteAvatar">{{ $t('delete') }}</button>
        </div>
    </div>
    
</template>

<style lang="scss">
    .avatar-container {
        width: 100%;
        max-width: 25rem;
        margin: 0 auto;
    }
    .upload-btns-wrapper {
        text-align: center;
        & > * {
            vertical-align: middle;
        }
    }
    #current-avatar {
        display: block;
        width: 15rem;
        height: 15rem;
        margin: 0 auto;
        padding: 1rem;
        background: linear-gradient(45deg, rgba(185, 147, 214, 0.5), rgba(140, 166, 219, 0.5)), 
                    linear-gradient(-45deg, rgba(214, 93, 177, 0.5), rgba(255, 111, 145, 0.5));
    }
</style>


<script>
import Vue              from 'vue';
import VueCroppie       from 'vue-croppie';

Vue.use(VueCroppie);

export default {
    data() {
        return {
            cropped         : null,
            initialized     : false, 
            inputFile       : false, 
            preparedFile    : null, 
            uploadedImage   : null,
        }
    },
    props: ['avatarUrl', 'prepare', 'save'], 
    watch: {
        prepare (newVal) {
            if (newVal)
                this.generate();
        },
        save (newVal) { // for NO FUCKING REASON, this is not working while "prepare" does.
            if (newVal) {
                this.submit();
            }
        }
    },
    methods: {
        bindFile() {
            var input = this.$refs.croppieFileInput;
            if (input.files && input.files[0]) {
	            var reader = new FileReader();
	            
	            reader.onload = (e) => {
                    this.inputFile = true;
                    setTimeout(() => {
                        this.$refs.croppieRef.bind({
                            url: e.target.result
                        });
                    }, 10);

	            }
	            reader.readAsDataURL(input.files[0]);
	        } 
        }, 
        generate() { 
            if (!this.$refs.croppieRef)
                return; 

            let options = {
                type: 'blob', 
                format: 'png', 
                circle: true,
                size: 'viewport'
            }
            this.$refs.croppieRef.result(options, (output) => {
                this.cropped        = output;
                this.preparedFile   = new File([output], 'avatar.' + options.format, 
                    {
                        type: 'image/png'
                    }
                );
            });
        }, 
        deleteAvatar() {
            this.inputFile = false;
        },
        handleUploadProgress (args) {
            for (var i = 0; i < args.running.length; i++) {
                if (args.running[i].status == 'complete') {
                    this.inputFile = false;
                    this.$emit('saved');
                }
            }
        }, 
        submit () {
            if (!this.inputFile) {
                this.$emit('saved');
                return;
            }

            FW.upload.append('User/@/Profile:addImage', this.preparedFile, 
                {
                    purpose: 'main'
                }
            );
            FW.upload.resume();
        },  
        _initUploader () {
            if (!this.initialized) {
                this.initialized = true;
                FW.load("sha256");
                FW.upload.onprogress = this.handleUploadProgress;
            }
        }, 
    },
    beforeMount () {
        this._initUploader();
        EventBus.$on('registration:avatar:save', () => { // see comment on watch "save"
            this.submit();
        });
    }
}
</script>