import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        allServices : false,
        contextLang : false, 
        countryList : false,
        i18nCatalog : false, 
        user        : false,
        userAvatar  : false, 
        userGroup   : false,
        userServices: false,
    }, 
    getters: {
        getAllServices  : state => state.allServices,
        getContextLang  : state => state.contextLang, 
        getCountryList  : state => state.countryList,
        getI18nCatalog  : state => state.getI18nCatalog,
        getUser         : state => state.user,
        getUserAvatar   : state => state.userAvatar, 
        getUserEmail    : state => state.user.Email, 
        getUserGroup    : state => state.userGroup, 
        getUserName     : state => state.user && state.user.Profile ? state.user.Profile.Display_Name : '',
        getUserServices : state => state.userServices,
        getGroupById    : (state) => (id) => {
            return state.userGroup[id];
        }, 
        getCountryById  : (state) => (id) => {
            return state.countryList[id];
        }
    },
    mutations: {
        INIT_ALL_SERVICES: (state, payload) => {
            if (!state.allServices) 
                state.allServices = payload;
        },
        INIT_CONTEXT_LANG: (state, payload) => {
            if (!state.contextLang)
                state.contextLang = payload; 
        },
        INIT_COUNTRY_LIST: (state, payload) => {
            if (!state.countryList) 
                state.countryList = payload;
        },
        INIT_I18N_CATALOG: (state, payload) => {
            if (!state.i18nCatalog) 
                state.i18nCatalog = payload;
        },
        INIT_USER: (state, payload) => {
            if (!state.user) {
                state.user       = payload;
                state.userAvatar = payload.Profile.Drive_Item ? payload.Profile.Drive_Item.Media_Image : {}; 
            }
        },
        INIT_USER_GROUP: (state, payload) => {
            if (!state.userGroup) 
                state.userGroup = payload;
        },
        INIT_USER_SERVICES: (state, payload) => {
            if (!state.userServices) 
                state.userServices = payload;
        },


        REMOVE_GROUP: (state, payload) => {
            Vue.delete(state.userGroup, payload);
        },

        SET_DEFAULT_ADDRESS: (state, payload) => { // payload = { type: Billing/License/Shipping, User_Location__: User_Location__ }
            switch(payload.type) {
                case 'Billing':
                    state.user.Default_Billing_User_Location__ = payload.User_Location__;
                    break;
                case 'License':
                    state.user.Default_License_User_Location__ = payload.User_Location__;
                    break;
                case 'Shipping':                
                    state.user.Default_Shipping_User_Location__ = payload.User_Location__;
                    break;
            }
        },
        SET_GROUP_NAME: (state, payload) => {
            state.userGroup[payload.id].Name = payload.name;
        },
        SET_NEW_GROUP: (state, payload) => {
            Vue.set(state.userGroup, payload.User_Group__, payload);
        },
        SET_USER_AVATAR: (state, payload) => {
            Object.keys(payload).forEach(element => {
                Vue.set(state.userAvatar, element, payload[element]);
            });
        }, 
        SET_USER_EMAIL: (state, payload) => {
            state.user.Email = payload;
        },
        SET_USER_NAME: (state, payload) => {
            state.user.Profile.Display_Name = payload;
        },
        
        UNSET_DEFAULT_ADDRESS: (state, payload) => { // payload = Billing/License/Shipping }
            switch(payload) {
                case 'Billing':
                    state.user.Default_Billing_User_Location__ = null;
                    break;
                case 'License':
                    state.user.Default_License_User_Location__ = null;
                    break;
                case 'Shipping':                
                    state.user.Default_Shipping_User_Location__ = null;
                    break;
            }
        },
        UNSET_USER_AVATAR: (state, payload) => {
            for(var prop in state.user.Profile.Media_Image) 
                Vue.delete(state.user.Profile.Media_Image, prop);
        }
    },
    actions: {
        initAllServices : (context, payload) => { context.commit('INIT_ALL_SERVICES', payload); },
        initContextLang : (context, payload) => { context.commit('INIT_CONTEXT_LANG', payload); }, 
        initCountryList : (context, payload) => { context.commit('INIT_COUNTRY_LIST', payload); },
        initI18nCatalog : (context, payload) => { context.commit('INIT_I18N_CATALOG', payload); },
        initUser        : (context, payload) => { context.commit('INIT_USER', payload); },
        initUserGroup   : (context, payload) => { context.commit('INIT_USER_GROUP', payload); },
        initUserServices: (context, payload) => { context.commit('INIT_USER_SERVICES', payload); },

        removeGroup     : (context, payload) => {
            context.commit('REMOVE_GROUP', payload);
        },

        setDefaultAddress   : (context, payload) => {
            context.commit('SET_DEFAULT_ADDRESS', payload);
        },
        setNewGroup         : (context, payload) => {
            context.commit('SET_NEW_GROUP', payload);
        },
        setGroupName        : (context, payload) => {
            context.commit('SET_GROUP_NAME', payload);
        }, 
            setUserAvatar       : (context, payload) => {
            context.commit('SET_USER_AVATAR', payload);
        }, 
        setUserEmail        : (context, payload) => {
            context.commit('SET_USER_EMAIL', payload);
        },
        setUserName         : (context, payload) => {
            context.commit('SET_USER_NAME', payload);
        },

        unsetDefaultAddress : (context, payload) => {
            context.commit('UNSET_DEFAULT_ADDRESS', payload);
        },
        unsetUserAvatar     : (context, payload) => {
            context.commit('UNSET_USER_AVATAR', payload);
        }
    }
})
