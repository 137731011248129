<template>
    <div class="login-container">
        <router-link class="btn" :to="{ name: 'Login' }"><i class="nova-arrow-left"></i> {{ $t('back') }}</router-link>
        <h1>{{ $t('label_new_password') }}</h1>
        <form action="" method="POST" @submit.prevent="submit">
            <div class="input-group">
                <password-input
                    :name="'pwd'"
                    :disabled="status"
                    :required="true"
                    @pwd-changed="value => p1 = value"
                /> 
            </div>
            <div class="input-group">
                <password-input
                    :name="'pwdConfirm'"
                    :disabled="status"
                    :required="true"
                    @pwd-changed="value => p2 = value"
                /> 
            </div>
            <p class="pwd-not-matching" v-show="pwdNotMatching">{{ $t('error_passwords_not_matching') }}</p>
            <save-button 
                :enabled="saveEnabled"
                :status="status"
            >
                <template slot="save">{{ $t('reset_password') }}</template>
                <template slot="saving">{{ $t('resetting') }}</template>
                <template slot="success">{{ $t('success') }}</template>
            </save-button>
        </form>
    </div>
</template>

<script>
import PasswordInput from './../../others/PasswordInput.vue';
import SaveBtn       from './../../others/SaveBtn.vue';

export default {
    components: {
        'password-input': PasswordInput,
        'save-button'   : SaveBtn,
    }, 
    data () {
        return {
            pwdNotMatching  : false, 
            p1              : '',
            p2              : '',
            status          : null
        };
    },
    computed: {
        key () {
            return this.$route.params.key;
        },
        login () {
            return this.$route.params.login;
        },
        saveEnabled () {
            return this.login
                    && this.key
                    && this.p1.length > 6
                    && this.p2 == this.p1; 
        }
    },
    watch: {
        passwordConfirm () {
            this.pwdNotMatching = this.p1 != this.p2;
        }
    },
    methods: {
        submit () {
            this.status = "saving";
            FW.rest('User:recover_password', 'POST', {
                key     : this.key, 
                login   : this.login, 
                p1      : this.p1, 
                p2      : this.p2
            }).done(data => {
                if (data.result == "success") {
                    this.status = "success";
                    FW.rest('User:login', 'POST', {
                        login   : this.login,
                        password: this.p1
                    }).done(data => {
                        if (data.result == "success") {
                            window.location = "/";
                        } else {
                            // TODO display error;
                        }
                    }).fail(data => {
                        // TODO display error;
                    });
                } else {
                    this.status = "error";
                    // TODO display error
                    console.log(data);
                }
            }).fail(data => {
                this.status = "error";
                // TODO display error
                console.log(data);
            }).always(data => {
                setTimeout(() => {
                    this.status = null;
                }, 1000);
            });
        }
    }
}
</script>
