var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "avatar-container" },
      [
        _vm.inputFile
          ? _c("vue-croppie", {
              ref: "croppieRef",
              attrs: {
                enableOrientation: true,
                enableResize: false,
                mouseWheelZoom: false,
                viewport: { width: 200, height: 200, type: "circle" },
                boundary: { width: 200, height: 200 }
              }
            })
          : _c("img", {
              ref: "currentAvatar",
              attrs: { src: _vm.avatarUrl, id: "current-avatar" }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "upload-btns-wrapper" }, [
      _c("label", { staticClass: "file-input btn secondary" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.$t("upload_image")) + "\n            "
        ),
        _c("input", {
          ref: "croppieFileInput",
          attrs: {
            id: "croppie-file-input",
            name: "croppie-file-input",
            type: "file",
            accept: "image/png, image/jpeg, image/jpg, image/svg"
          },
          on: { change: _vm.bindFile }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn danger",
          attrs: { type: "button" },
          on: { click: _vm.deleteAvatar }
        },
        [_vm._v(_vm._s(_vm.$t("delete")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }