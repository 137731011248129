<template>
    <div class="ie-warning-banner" v-if="!isDismissed">
        <div class="container">
            <div class="columns is-gapless">
                <div class="column icon-wrapper">
                    <i class="icon nova-smiley-worry"></i>
                </div>
                <div class="column is-four-fifths">
                    <p><strong v-html="$t('ie_banner_title')"></strong></p>
                    <p v-html="$t('ie_banner_content', {
                        chrome: 'https://www.google.com/intl/fr_ALL/chrome/',
                        edge: 'https://www.microsoft.com/fr-fr/windows/microsoft-edge',
                        firefox: 'https://www.mozilla.org/fr/firefox/new/'
                    })"></p>
                </div>
                <div class="column">
                    <button class="btn warning" @click="dismiss">{{ $t('dismiss') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import './../../../assets/css/_variables.scss';
body.banner {
    padding-top: 12rem;
    .top-bar {
        top: 8rem;
    }
}
.ie-warning-banner {
    box-sizing: border-box;
    position: fixed;
    top: 0; left: 0;
    width: 100vw; height: 8rem;
    padding: 1rem 4rem;
    background: linear-gradient(to right, $gradient-warning-alt);
    color: $color-text;
    text-align: center;
    z-index: 10;
    .icon-wrapper {
        text-align: right;
    }
    .icon {
        display: block;
        padding: 1rem 2rem;
        font-size: 2rem;
        opacity: .6;
    }
    p {
        margin: .5rem 0;
        text-align: justify;
    }
    a {
        color: $color-base;
    }
}
</style>

<script>
export default {
    data () {
        return {
            isDismissed: false
        }
    },
    methods: {
        dismiss () {
            document.cookie     = 'iebannerdismissed';
            this.isDismissed    = true;
            document.getElementsByTagName('body')[0].classList.remove('banner');
        },
        hasDismissCookie () {
            return !!(document.cookie.match(new RegExp('(^| )iebannerdismissed([^;]+)')));
        }
    },
    beforeMount () {
        this.isDismissed = this.hasDismissCookie();
        if (!this.isDismissed && this.isMSIE())
            document.getElementsByTagName('body')[0].classList.add('banner');
    }
}
</script>
