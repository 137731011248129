var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "saving-status" }, [
    _vm.status == "saving"
      ? _c("span", [_vm._v(_vm._s(_vm.customMessage || _vm.savingText))])
      : _vm.status == "saved"
      ? _c("span", [_vm._v(_vm._s(_vm.customMessage || _vm.savedText))])
      : _vm.status == "error"
      ? _c("span", [_vm._v(_vm._s(_vm.customMessage || _vm.errorText))])
      : _c("span", [_vm._v(_vm._s(_vm.lastEdit) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }