var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "router-link",
        { staticClass: "btn", attrs: { to: { name: "Login" } } },
        [
          _c("i", { staticClass: "nova-arrow-left" }),
          _vm._v(" " + _vm._s(_vm.$t("back")))
        ]
      ),
      _vm._v(" "),
      _c("h1", [_vm._v(_vm._s(_vm.$t("password_lost")))]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "#", method: "POST" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "input-group pretty-placeholder" }, [
            _c("label", { attrs: { for: "user-id" } }, [
              _vm._v(_vm._s(_vm.$t("label_email")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.login,
                  expression: "login"
                }
              ],
              attrs: {
                type: "email",
                id: "user-email",
                name: "user-email",
                placeholder: " ",
                disabled: _vm.loading
              },
              domProps: { value: _vm.login },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.login = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "placeholder", attrs: { for: "user-email" } },
              [
                _c("i", { staticClass: "icon nova-envelope-1" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("placeholder_email")))])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group align-right" },
            [
              _c(
                "save-button",
                {
                  staticClass: "btn primary",
                  attrs: { enabled: _vm.submitEnabled, status: _vm.status }
                },
                [
                  _c("template", { slot: "save" }, [
                    _vm._v(_vm._s(_vm.$t("reset_password")))
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "saving" }, [
                    _vm._v(_vm._s(_vm.$t("resetting")))
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "success" }, [
                    _vm._v(_vm._s(_vm.$t("email_sent")))
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }