var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDismissed
    ? _c("div", { staticClass: "ie-warning-banner" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns is-gapless" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "column is-four-fifths" }, [
              _c("p", [
                _c("strong", {
                  domProps: { innerHTML: _vm._s(_vm.$t("ie_banner_title")) }
                })
              ]),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("ie_banner_content", {
                      chrome: "https://www.google.com/intl/fr_ALL/chrome/",
                      edge:
                        "https://www.microsoft.com/fr-fr/windows/microsoft-edge",
                      firefox: "https://www.mozilla.org/fr/firefox/new/"
                    })
                  )
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column" }, [
              _c(
                "button",
                { staticClass: "btn warning", on: { click: _vm.dismiss } },
                [_vm._v(_vm._s(_vm.$t("dismiss")))]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column icon-wrapper" }, [
      _c("i", { staticClass: "icon nova-smiley-worry" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }