var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-wrapper" },
    [
      _c(
        "router-link",
        {
          staticClass: "btn register-back-btn",
          attrs: { to: { name: "Login" } }
        },
        [
          _c("i", { staticClass: "nova-arrow-left" }),
          _vm._v(" " + _vm._s(_vm.$t("back")))
        ]
      ),
      _vm._v(" "),
      _c("h1", [_vm._v(_vm._s(_vm.$t("create_new_account")))]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "panel-transition", mode: "out-in" } },
        [
          !_vm.loading
            ? _c(
                "form",
                {
                  key: "panel-1",
                  staticClass: "panel",
                  attrs: { action: "", method: "POST" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "input-group" }, [
                    _c("label", { attrs: { for: "register-email-address" } }, [
                      _vm._v(_vm._s(_vm.$t("label_email")) + "*")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.emailAddress,
                          expression: "emailAddress"
                        }
                      ],
                      attrs: {
                        id: "register-email-address",
                        type: "email",
                        placeholder: _vm.$t("placeholder_email"),
                        disabled: _vm.status,
                        required: ""
                      },
                      domProps: { value: _vm.emailAddress },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.emailAddress = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("label", { attrs: { for: "register-password" } }, [
                        _vm._v(_vm._s(_vm.$t("label_password")) + "*")
                      ]),
                      _vm._v(" "),
                      _c("password-input", {
                        attrs: {
                          id: "register-password",
                          name: "pwd",
                          disabled: _vm.status,
                          required: true
                        },
                        on: {
                          "pwd-changed": function(value) {
                            return (_vm.password = value)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c(
                        "label",
                        { attrs: { for: "register-password-confirm" } },
                        [_vm._v(_vm._s(_vm.$t("label_password_confirm")) + "*")]
                      ),
                      _vm._v(" "),
                      _c("password-input", {
                        attrs: {
                          id: "register-password-confirm",
                          name: "pwd-confirm",
                          disabled: _vm.status,
                          required: true
                        },
                        on: {
                          "pwd-changed": function(value) {
                            return (_vm.passwordConfirm = value)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pwdNotMatching,
                          expression: "pwdNotMatching"
                        }
                      ],
                      staticClass: "pwd-not-matching"
                    },
                    [_vm._v(_vm._s(_vm.$t("error_passwords_not_matching")))]
                  ),
                  _vm._v(" "),
                  _vm.error
                    ? _c("p", { staticClass: "pwd-not-matching" }, [
                        _vm._v(_vm._s(_vm.error))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.acceptToS,
                          expression: "acceptToS"
                        }
                      ],
                      attrs: {
                        id: "register-accept-tos",
                        type: "checkbox",
                        required: ""
                      },
                      domProps: {
                        checked: Array.isArray(_vm.acceptToS)
                          ? _vm._i(_vm.acceptToS, null) > -1
                          : _vm.acceptToS
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.acceptToS,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.acceptToS = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.acceptToS = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.acceptToS = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", {
                      attrs: { for: "register-accept-tos" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("accept_tos", { tosLink: _vm.tosLink })
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "save-button",
                    { attrs: { enabled: _vm.saveEnabled, status: _vm.status } },
                    [
                      _c("template", { slot: "save" }, [
                        _vm._v(_vm._s(_vm.$t("register")))
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "saving" }, [
                        _vm._v(_vm._s(_vm.$t("registering")))
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "success" }, [
                        _vm._v(_vm._s(_vm.$t("registered")))
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "div",
                { key: "panel-2", staticClass: "panel" },
                [
                  _c("loader"),
                  _vm._v(" "),
                  _vm.status != "success"
                    ? _c("p", [
                        _vm._v(_vm._s(_vm.$t("registration_in_progress")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-status" }, [
                    _c("p", { staticClass: "success" }, [
                      _vm._v(_vm._s(_vm.$t("registration_successfull")))
                    ])
                  ])
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }