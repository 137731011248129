import Vue      from 'vue';
import Router   from 'vue-router';

import ForgottenPassword    from '../../templates/components/authentication/ForgottenPassword/ForgottenPassword.vue';
import Login                from '../../templates/components/authentication/Login/Login.vue';
import { mapActions }       from 'vuex';
import PageLogin            from '../../templates/pages/Login/Login.vue';
import PageNotFound         from '../../templates/components/errors/PageNotFound.vue';
import ResetPassword        from '../../templates/components/authentication/ResetPassword/ResetPassword.vue';
import Register             from '../../templates/components/authentication/Register/Register.vue';
import SavingStatus         from './plugins/SavingStatus/SavingStatus.js';
import store                from './_store.js';
import translationUtility   from './_translationUtility.js';
import { format } from 'date-fns/esm';

Vue.use(Router);
Vue.use(SavingStatus); 

Vue.mixin({
    methods: Object.assign({
        isMSIE() {
            return (navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
        },
        appendMSIEAdditionnalStyle() {
            var head  = document.getElementsByTagName('head')[0];
            var link  = document.createElement('link');
            link.id   = "ie11-fixes";
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = 'build/ie11.css';
            link.media = 'all';
            head.appendChild(link);

            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie11');
        }
    }, translationUtility)
});

const router = new Router({
    routes  : [
        // {
            // path: '/',
            // name: 'Login',
            // component: Login,
            // meta: {
            //     title: route => "Welcome to  AtOnline"
            // }
        // },
        {
            path: '/forgotten-password',
            name: 'ForgottenPassword',
            component: ForgottenPassword,
            meta: {
                title: route => "Password lost"
            }
        },
        {
            path: '/reset-password/:key/:login',
            name: 'ResetPassword',
            component: ResetPassword, 
            meta: {
                title: route => "Reset password"
            }
        },
        {
            path: "*",
            name: 'Login',
            component: Login,
            meta: {
                title: route => "Welcome to  AtOnline"
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    // need to handle eventual fragment in return_to value; if not, it breaks vue router navigation
    var splitted = window.location.href.split('return_to=');
    if (splitted.length > 1 ) {
        var arg = splitted[1];
        if (arg.substr(-2) == "#/")
            arg = arg.substr(0, arg.length - 2);
            
        if (arg.indexOf('/') >= 0 && splitted[0].indexOf('login.html') >= 0) { 
            window.location.href = window.origin + "/login.html?return_to=" + encodeURIComponent(arg);
            next(false);
            return;
        }
    }
    document.title = to.meta.title(to) + ' - AtOnline Hub';
    next();
});

window.EventBus = new Vue();

window.login = new Vue({ 
    data() {
        return {};
    }, 
    router: router,
    store: store, 
    delimiters: ["${","}"], 
    el: '#login', 
    render: h => h(PageLogin),
    methods: {
        ...mapActions([
            'initI18nCatalog'
        ])
    },
    beforeMount() {
        // IE; doing this instead of just CSS hack to avoid loading unneeded style for other browsers than IE
        if (this.isMSIE())
            this.appendMSIEAdditionnalStyle();

        Vue.prototype.$common = {
            // 'routes': JSON.parse(this.$el.attributes['data-routes'].value), 
            'userYubikey'  : window.userYubikey,
            'sessionOnce'  : document.body.attributes['data-token'].value,
            'oauth'        : window.oauth,
        };
        this.initI18nCatalog(window.i18nCatalog);
        delete window.i18nCatalog;
        delete window.oauth;
        delete window.userYubikey;
        var initScript = document.getElementById('init-data-script');
        initScript.parentNode.removeChild(initScript);
    }, 
});