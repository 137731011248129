<template>
    <span class="pwd-input">
        <input 
            :type="showPwd ? 'text' : 'password'" 
            v-model="pwd"
            :disabled="disabled"
            :name="name"
            :required="required"
            :placeholder="$t('placeholder_new_password')"
        />
        <button 
            type="button"
            @mousedown="showPwd = true"
            @mouseup="showPwd = false"
            @mouseout="showPwd = false"
            tabindex="-1"
        > <i v-if="!showPwd" class="nova-eye"></i><i v-else class="nova-eye-off"></i></button>
    </span>
</template>

<style lang="scss" scoped>
    .pwd-input {
        position: relative;
        display: inline-block;
        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            border-radius: 0 2rem 2rem 0;
        }
    }
</style>

<script>
export default {
    data () {
        return {
            pwd     : '', 
            showPwd : false
        };
    },
    props: ['name', 'disabled', 'required'],
    watch: {
        pwd (value) {
            this.$emit('pwd-changed', value);
        } 
    }
}
</script>
