var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "save-btn btn secondary",
      attrs: { type: "submit", disabled: !_vm.enabled }
    },
    [
      _vm.status == "saving"
        ? _c("span", [_vm._t("saving", [_vm._v(_vm._s(_vm.$t("saving")))])], 2)
        : _vm.status == "success"
        ? _c("span", [_vm._t("success", [_vm._v(_vm._s(_vm.$t("saved")))])], 2)
        : _vm.status == "error"
        ? _c("span", [_vm._t("error", [_vm._v(_vm._s(_vm.$t("failure")))])], 2)
        : _c("span", [_vm._t("save", [_vm._v(_vm._s(_vm.$t("save")))])], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }