import Loader        from './../../others/Loader.vue';
import PasswordInput from './../../others/PasswordInput.vue';
import SaveBtn       from './../../others/SaveBtn.vue';

export default {
    components: {
        'loader'        : Loader, 
        'password-input': PasswordInput,
        'save-button'   : SaveBtn,
    },
    data () {
        return {
            acceptToS       : false, 
            emailAddress    : '', 
            error           : null, 
            loading         : false, 
            password        : '',
            passwordConfirm : '',
            pwdNotMatching  : false,
            showPwd         : false,
            status          : null,
        };
    },
    computed: {
        saveEnabled () {
            return this.emailAddress.trim().length > 0 
                    && this.password.length > 0
                    && this.passwordConfirm == this.password
                    && this.acceptToS;
        },
        tosLink () {
            return "https://www.atonline.com/l/" + FW.Context['l'] + "/page/tos";
        } 
    },
    watch: {
        passwordConfirm () {
            this.pwdNotMatching = this.password != this.passwordConfirm;
        }
    },
    methods: {
        submit () {
            this.status     = "saving";
            this.error      = null;
            this.loading    = true;
            FW.rest('User', 'POST', {
                Email   : this.emailAddress,
                Password: this.password
            }).done(data => {
                if (data.result == "success") {
                    this.status = "success";
                    FW.rest('User:login', 'POST', {
                        login   : this.emailAddress, 
                        password: this.password
                    }).done(data => {
                        if (data.result == "success") {
                            window.location = "/";
                        } else {
                            // TODO display error
                        }
                    }).fail(data => {
                        this.error = data.error;
                    });
                } else {
                    this.loading    = false;
                    this.status     = "error";
                    this.error      = data.error;
                    setTimeout(() => {
                        this.status = null;
                    }, 1000);
                }
            }).fail(data => {
                this.status     = "error";
                this.loading    = false;
                this.error      = data.responseJSON.error;
                // TODO display error
                console.log(data);
                setTimeout(() => {
                    this.status = null;
                }, 1000);
            });
        }
    }
};