var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-comp" },
    [
      _c("msie-banner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isMSIE(),
            expression: "isMSIE()"
          }
        ]
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "page-transition", mode: "out-in" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }