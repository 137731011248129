import SaveBtn from './../../others/SaveBtn.vue';

export default {
    components: {
        'save-button': SaveBtn
    },
    data () {
        return {
            loading         : false,
            login           : '',
            status          : null, 
            submitEnabled   : false,
        };
    },
    watch: {
        login () {  
            this.submitEnabled = this.login.trim().length > 0;
        }
    },
    methods: {
        submit () {
            this.submitEnabled  = false;
            this.loading        = true;
            this.status         = "saving";
            FW.rest('User:forgot_password', 'POST', {
                login: this.login
            }).done(data => {
                if (data.result == "success") {
                    this.status = "success";
                } else {
                    this.status = "error";
                    //TODO display error
                }
            }).fail(data => {
                this.status = "error";
                // TODO display error
            }).always(data => {
                this.loading        = false;
                this.submitEnabled  = true;
                setTimeout(() => {
                    this.status = null;
                }, 1000);
            });
        }
    }
};