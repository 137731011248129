<template>
    <div class="saving-status">
        <span v-if="status == 'saving'">{{ customMessage || savingText }}</span>
        <span v-else-if="status == 'saved'">{{ customMessage || savedText }}</span>
        <span v-else-if="status == 'error'">{{ customMessage || errorText }}</span>
        <span v-else>{{ lastEdit }} </span>
    </div>
</template>

<style lang="scss">
    .saving-status {
        margin: .5rem;
    }
</style>

<script>
import SavingStatusDisplay from './SavingStatus.js';

export default {
    data () {
        return {
            coolingInterval : null,
            customMessage   : null,
            lastEditTime    : null,
            status          : null
        };
    }, 
    computed: {
        errorText () {
            return window && window.vm && window.vm.$t ? window.vm.$t('error') : "Error :(";
        }, 
        lastEdit () {
            var text = '';
            if (window.vm && window.vm.$t)
                text = this.lastEditTime ? window.vm.$t('last_edited_at_time', { time: this.lastEditTime }) : '';
            else 
                text = this.lastEditTime ? 'last edited at ' + this.lastEditTime : '';
            
            return text;
        },
        savingText () {
            return window && window.vm && window.vm.$t ? window.vm.$t('saving') : "Saving...";
        },
        savedText () {
            return window && window.vm && window.vm.$t ? window.vm.$t('saved') : "Saved!";
        }
    },
    watch: {
        status () {
            this.resetStatus();
        }
    },
    methods: {
        getCurrentTime () {
            var d = new Date(),
                h = (d.getHours()   < 10 ? '0' : '') + d.getHours(),
                m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
            
            return h + ':' + m;
        },
        resetStatus () {
            if (this.coolingInterval) 
                clearInterval(this.coolingInterval);
            
            this.coolingInterval = self.setInterval(() => {
                if (this.status && this.status != 'saving') {
                    setTimeout(() => {
                        this.status = null;
                    }, 1000);
                    this.coolingInterval    = null;
                    this.lastEditTime       = this.getCurrentTime();
                    this.customMessage      = null;
                }
            }, 2000);
        }
    },
    beforeMount () {
        SavingStatusDisplay.event.$on('status', status => {
            this.status        = status;
            this.customMessage = null;
        });
        SavingStatusDisplay.event.$on('status:custom', args => {
            this.status        = args.status;
            this.customMessage = args.message;
        })
    }
}
</script>
