var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "footer" },
    [
      _c(
        "transition",
        { attrs: { name: "simple-transition" } },
        [
          !_vm.loading
            ? _c(
                "drop-down",
                {
                  staticClass: "dropup",
                  attrs: { id: "footer-languages-dropdown" }
                },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v(" " + _vm._s(_vm.langLabel) + " "),
                    _c("i", { staticClass: "nova-chevron-up caret" })
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "content" },
                    _vm._l(_vm.languages, function(lang) {
                      return _c(
                        "a",
                        {
                          key: lang.Language__,
                          attrs: {
                            href:
                              "/l/" +
                              lang.Language_Lowercase +
                              "/#" +
                              _vm.$router.currentRoute.path
                          }
                        },
                        [_vm._v(_vm._s(lang.Local_Name))]
                      )
                    }),
                    0
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "/law.html", target: "_blank" } },
        [_vm._v(_vm._s(_vm.$t("law_title")))]
      ),
      _vm._v(" "),
      _vm.$store.state.user
        ? [
            _c(
              "router-link",
              { staticClass: "btn", attrs: { to: { name: "Contact" } } },
              [
                _c("i", { staticClass: "nova-chat-bubble-square-smiley" }),
                _vm._v(" " + _vm._s(_vm.$t("contact")))
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "mentions" }, [
        _vm._v(
          "©" +
            _vm._s(new Date().getFullYear()) +
            " Tristan Technologies - " +
            _vm._s(_vm.$t("all_rights_reserved"))
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }