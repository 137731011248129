import SavingStatusDisplay from './SavingStatusDisplay.vue';

const Plugin = {
    install (Vue, options = {}) {
        
        /** 
         * Making sure the plugin is installed only once
         */
        if (this.installed)
            return;

        this.installed = true;
        this.event = new Vue();

        Vue.component('saving-status', SavingStatusDisplay);

        /**
         * Plugin API
         */
        Vue.prototype.$status = {
            custom(args) {
                Plugin.event.$emit('status:custom', args);
            }, 
            failed() {
                Plugin.event.$emit('status', 'error');
            }, 
            saved() {
                Plugin.event.$emit('status', 'saved');
            }, 
            saving() {
                Plugin.event.$emit('status', 'saving');
            }
        };
    }
};

export default Plugin;