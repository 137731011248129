<template>
    <button 
        type="submit" 
        :disabled="!enabled"
        class="save-btn btn secondary"
    >
        <span v-if="status == 'saving'"><slot name="saving">{{ $t('saving') }}</slot></span>
        <span v-else-if="status == 'success'"><slot name="success">{{ $t('saved') }}</slot></span>
        <span v-else-if="status == 'error'"><slot name="error">{{ $t('failure') }}</slot></span>
        <span v-else><slot name="save">{{ $t('save') }}</slot></span>
    </button>
</template>

<style lang="scss"></style>

<script type="javascript">
import { mapState } from 'vuex';
export default {
    data () {
        return {};
    },
    props: ['enabled', 'status'],
    computed: {
        ...mapState({
            trans: state => state.i18nCatalog
        })
    }
}
</script>
