<template>
    <div class="error-wrapper">
        <div class="error-inner">
            <h2>{{ $t('page_not_found') }}</h2>
            <p>{{ $t('page_not_found_description') }}</p> 
            <a href="#" class="btn primary" @click.prevent="$router.go(-1)"> {{ $t('back') }}</a>
        </div>
    </div>
</template>

<style lang="scss">
.error-wrapper,
.error-inner {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}
.error-wrapper {
    width: 100%; height: 100%;
}
.error-inner {
    box-sizing: border-box;
    width: 100%;
    max-width: 45rem;
    margin: 2rem auto;
    padding: 2rem;
    background-color: rgba(255,255,255, .8);
    border: .1rem solid #8ca6db;
    h1, h2, .align-center {
        text-align: center;
    }
}
</style>

<script>
export default {
    data () {
        return {};
    }
}
</script>


