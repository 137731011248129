var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "pwd-input" }, [
    (_vm.showPwd ? "text" : "password") === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pwd,
              expression: "pwd"
            }
          ],
          attrs: {
            disabled: _vm.disabled,
            name: _vm.name,
            required: _vm.required,
            placeholder: _vm.$t("placeholder_new_password"),
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.pwd)
              ? _vm._i(_vm.pwd, null) > -1
              : _vm.pwd
          },
          on: {
            change: function($event) {
              var $$a = _vm.pwd,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.pwd = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.pwd = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.pwd = $$c
              }
            }
          }
        })
      : (_vm.showPwd ? "text" : "password") === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pwd,
              expression: "pwd"
            }
          ],
          attrs: {
            disabled: _vm.disabled,
            name: _vm.name,
            required: _vm.required,
            placeholder: _vm.$t("placeholder_new_password"),
            type: "radio"
          },
          domProps: { checked: _vm._q(_vm.pwd, null) },
          on: {
            change: function($event) {
              _vm.pwd = null
            }
          }
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pwd,
              expression: "pwd"
            }
          ],
          attrs: {
            disabled: _vm.disabled,
            name: _vm.name,
            required: _vm.required,
            placeholder: _vm.$t("placeholder_new_password"),
            type: _vm.showPwd ? "text" : "password"
          },
          domProps: { value: _vm.pwd },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.pwd = $event.target.value
            }
          }
        }),
    _vm._v(" "),
    _c(
      "button",
      {
        attrs: { type: "button", tabindex: "-1" },
        on: {
          mousedown: function($event) {
            _vm.showPwd = true
          },
          mouseup: function($event) {
            _vm.showPwd = false
          },
          mouseout: function($event) {
            _vm.showPwd = false
          }
        }
      },
      [
        !_vm.showPwd
          ? _c("i", { staticClass: "nova-eye" })
          : _c("i", { staticClass: "nova-eye-off" })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }