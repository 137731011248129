var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-wrapper" }, [
    _c("div", { staticClass: "error-inner" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("page_not_found")))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("page_not_found_description")))]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn primary",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.$router.go(-1)
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("back")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }